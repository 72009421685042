class AddRowButton extends HTMLButtonElement {
	connectedCallback() {
		this.classList.add("btn");
		const selector = this.getAttribute("table");
		this.table = document.querySelector(`#${selector}`);
		this.template = document.querySelector(`#${selector}-template`);
		this.addEventListener("click", (e) => {
			e.preventDefault();
			this.addRow();
		});
		const countInput = document.createElement("input");
		countInput.type = "hidden";
		countInput.name = `${selector}_table-row-count`;
		countInput.value = this.table.querySelectorAll("tbody tr").length;
		this.appendChild(countInput);
		this.countInput = countInput;
	}

	addRow() {
		const currentCount = parseInt(this.countInput.value);

		let tbody = this.table.querySelector("tbody");

		let clone = this.template.content.cloneNode(true);
		clone.querySelectorAll("input, select, textarea").forEach((input) => {
			let newName = input.name.replace("x_", `${currentCount}_`);
			input.name = newName;
		});
		tbody.appendChild(clone);

		this.countInput.value = currentCount + 1;
	}
}

customElements.define("add-row-button", AddRowButton, { extends: "button" });

class DeleteRowButton extends HTMLButtonElement {
	connectedCallback() {
		this.row = this.closest("tr");
		this.addEventListener("click", (e) => {
			e.preventDefault();
			this.row.remove();
		});
	}

	deleteRow() {
		this.row.remove();
	}
}

customElements.define("delete-row-button", DeleteRowButton, {
	extends: "button",
});
