class ShowHide extends HTMLElement {
	connectedCallback() {
		const controls = this.querySelectorAll(":scope > .form-field input");
		this.target = this.querySelector("show-hide-target");
		this.input = this.target.querySelector("input");

		let somethingChecked = false;
		controls.forEach((c) => {
			if (c.checked) {
				somethingChecked = true;
				this.onTriggerChange(c.value);
			}
			c.addEventListener("change", (e) => {
				this.onTriggerChange(e.target.value);
			});
		});

		if (!somethingChecked) {
			this.onTriggerChange(false);
		}
	}

	onTriggerChange(value) {
		if (value == "Yes") {
			this.target.removeAttribute("is-hidden");
			this.input.required = true;
		} else {
			this.target.setAttribute("is-hidden", "true");
			this.input.required = false;
		}
	}
}

class ShowHideTarget extends HTMLElement {
	static get observedAttributes() {
		return ["is-hidden"];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === "is-hidden") {
			if (newValue) {
				this.style.display = "none";
			} else {
				this.style.display = "block";
			}
		}
	}
}

customElements.define("show-hide", ShowHide);
customElements.define("show-hide-target", ShowHideTarget);
