class FormSet extends HTMLElement {
	connectedCallback() {
		this.formPrefix = this.getAttribute("prefix");
		this.formCounter = this.querySelector(`#id_${this.formPrefix}-TOTAL_FORMS`);
		this.addButton = this.querySelector("button[data-add]");
		this.addTarget = this.querySelector("[data-add-target]");
		this.addButton.addEventListener("click", (e) => {
			e.preventDefault();
			this.addButtonClicked();
		});

		this.querySelectorAll("[data-inner-form]").forEach(this.setupDelete);
	}

	setupDelete(innerForm) {
		let deleteButton = innerForm.querySelector("button[data-delete]");
		if (!deleteButton) return;
		deleteButton.addEventListener("click", (e) => {
			e.preventDefault();
			innerForm.querySelector("[data-delete-checkbox]").checked = true;
			innerForm.className = "hidden";
		});
	}

	addButtonClicked() {
		let formTemplate = this.querySelector("[data-form-clone]");
		let newForm = formTemplate.content.firstElementChild.cloneNode(true);
		const count = parseInt(this.formCounter.value);
		newForm.innerHTML = newForm.innerHTML.replace(/__prefix__/g, `${count}`);
		this.insertBefore(newForm, this.addTarget);
		this.setupDelete(newForm);
		this.formCounter.value = count + 1;
	}
}

customElements.define("form-set", FormSet);
