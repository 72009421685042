import "../scss/app.scss";

// SVGs to make into sprite, paths relative to app.js before move
var __svg__ = {
	path: "../svgs/sprite/*.svg",
	name: "../../bet/jinja2/svgs/sprite.svg",
};

// Polyfill all the things...
import "whatwg-fetch";
// For anything not-DOM (eg String.repeat, Promise, Array.fill etc) use core-js.
// See available options here -> https://github.com/zloirock/core-js#features
import "core-js/es/array/from";
import "core-js/features/promise";

// We manually include the DOM polyfills we believe are necesary.
import applyDomPolyfills from "./includes/dompolyfills";

applyDomPolyfills();

// Production code goes after here
import { OutdatedBrowserNotice } from "@neonjungle/birdseed/browser/OutdatedBrowserNotice";

import attributesForCSS from "./includes/attributesForCSS";
import updateClampedCSS from "./includes/updateClampedCSS";
import navHighliger from "./includes/navHighlighter";
import "./includes/htmx";

import "./components/AddRowButton";
import "./components/DragDrop.jsx";
import "./components/FormSet";
import "./components/ShowHide";

// Should happen before the page has finished loading
// to update font sizes ASAP
updateClampedCSS();

document.addEventListener("DOMContentLoaded", function () {
	attributesForCSS();
	navHighliger();

	new OutdatedBrowserNotice(false, (browserOutdated) => {
		if (browserOutdated) {
			document.body.classList.add("browser-is-outdated");
		}
	});

	document.querySelectorAll("[data-print]").forEach((btn) => {
		btn.addEventListener("click", () => {
			window.print();
		});
	});

	document.body.classList.remove("preload");
});
