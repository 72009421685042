export default _ => {
    let nav = document.querySelector('.bid-body__nav');
    if(!nav) {
        return
    }
    let currentActiveId = null;

    const updateActive = (newId) => {
        if (currentActiveId && newId != currentActiveId) {
            let lastLink = document.querySelector(`a[href="#${currentActiveId}"]`);
            lastLink.classList.remove('is-active');
        }
        let sidebarLink = document.querySelector(`a[href="#${newId}"]`);
        sidebarLink.classList.add('is-active');
        currentActiveId = newId;
    }


    // Scrolls
    const intersectCallback = (entries, observer) => {
        let firstIntersetcting = entries.find(e => e.isIntersecting);
        if (firstIntersetcting && firstIntersetcting.target.id) {
            let intersectingId = firstIntersetcting.target.id;
            updateActive(intersectingId);
        }
    }


    let scrollObserver = new IntersectionObserver(
        intersectCallback, {
        root: null,
        threshold: 0.1,
    })
    document.querySelector('.bid-body__content').querySelectorAll('section, h2').forEach(section => {
        scrollObserver.observe(section);
    });


    // Clicks
    let clickTimeout = null;
    nav.querySelectorAll('a').forEach(navLink => {
        navLink.addEventListener('click', _ => {
            clearTimeout(clickTimeout);
            // We use a timeout to override the intersection observer
            clickTimeout = setTimeout(_ => {
                let clickedId = navLink.href.split('#')[1];
                updateActive(clickedId)
            }, 300);
        })
    })
}